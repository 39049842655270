import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/404-images/404.png'
import _imports_1 from '@/assets/404-images/404-cloud.png'


const _hoisted_1 = { class: "wscn-http404-container" }
const _hoisted_2 = { class: "wscn-http404" }
const _hoisted_3 = { class: "text-404" }
const _hoisted_4 = { class: "text-404__headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"pic-404\" data-v-eb849960><img class=\"pic-404__parent\" src=\"" + _imports_0 + "\" alt=\"404\" data-v-eb849960><img class=\"pic-404__child left\" src=\"" + _imports_1 + "\" alt=\"404\" data-v-eb849960><img class=\"pic-404__child mid\" src=\"" + _imports_1 + "\" alt=\"404\" data-v-eb849960><img class=\"pic-404__child right\" src=\"" + _imports_1 + "\" alt=\"404\" data-v-eb849960></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-404__oops" }, " OOPS! ", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-404__info" }, [
          _createTextVNode(" All rights reserved "),
          _createElementVNode("a", {
            style: {"color":"#20a0ff"},
            href: "https://wallstreetcn.com",
            target: "_blank"
          }, "wallstreetcn")
        ], -1)),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-404__info" }, " Please check that the URL you entered is correct, or click the button below to return to the homepage. ", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("a", {
          href: "",
          class: "text-404__return-home"
        }, "Back to home", -1))
      ])
    ])
  ]))
}